import React from 'react';
import { Col, Row } from 'reactstrap';
import DragHandle from '../../events/components/QuestionnaireBuilder/DragHandle';
import EditPencilIcon from '../../events/components/QuestionnaireBuilder/icons/EditPencilIcon';
import DeleteIcon from '../../events/components/QuestionnaireBuilder/icons/DeleteIcon';
import UpArrowIcon from '../../events/components/QuestionnaireBuilder/icons/UpArrowIcon';
import DownArrowIcon from '../../events/components/QuestionnaireBuilder/icons/DownArrowIcon';
import QuestionType, { QuestionTypeEnum } from '../../events/components/QuestionnaireBuilder/QuestionType';
import { optionsAvailable } from './utils';
import t, { partial } from '../../../util/translation/translation';

const mapQuestionType = (type) => {
  switch (type) {
    case 'TEXT_INPUT': return QuestionTypeEnum.TEXT_FIELD;
    case 'CHECKBOX': return QuestionTypeEnum.CHECKBOX;
    case 'DROPDOWN': return QuestionTypeEnum.DROPDOWN;
    case 'RADIO_BUTTONS': return QuestionTypeEnum.RADIO_GROUP;
    case 'SCORE_PREDICTOR': return QuestionTypeEnum.SCORE_PREDICTOR;
    case 'PLAYER_PICKER': return QuestionTypeEnum.PLAYER_PICKER;
    default: return null;
  }
};

const Options = ({ value, type }) => {
  switch (type) {
    // case 'TEXT_INPUT': return QuestionTypeEnum.TEXT_FIELD;
    case 'CHECKBOX': case 'DROPDOWN': case 'RADIO_BUTTONS': case 'SCORE_PREDICTOR':
      return (
        <div className="mt-1">
          <strong className="ml-4 block" >{t('question.options')}:</strong>
          <div className="ml-4 my-1">{value?.availableOptions?.map(i => (<div style={{ display: 'inline-block' }} className="ml-1 question-type" color="primary" pill>{i.title}</div>))}</div>
        </div>
      );
    case 'PLAYER_PICKER': return (<div className="mt-1"><strong className="ml-4" >{t('shared.team')}: {value?.teamId?.label}</strong></div>);
    default: return null;
  }
};

export default function PickedQuestionItem(props) {
  const { question, onMoveUp, onMoveDown, onDelete, onEdit, setRequired, hasAnswers } = props;
  const shouldMoveUpDisabled = props.first;
  const shouldMoveDownDisabled = props.last;
  const questionType = mapQuestionType(question.type);
  const qT = partial('question');
  return (
    <div
      key={question.id}
      className="picked-question-card"
    >
      <div className="content-area">
        <Row noGutters>
          <Col className="question-content">
            <div className="float-left draggable-handle"><DragHandle /></div>
            <p className="question-description" >{question.title}<br/>
              <small style={{ color: '#4D576A' }}>{question.description}</small>
            </p>
          </Col>
          <Col className="col-3 d-flex justify-content-end">
            <QuestionType type={questionType} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Options value={question.value} type={question.type} />
          </Col>
        </Row>
      </div>
      <hr />
      <div className="content-area">
        <Row noGutters>
          <Col>
            <div className="form-check">
              <label className="form-check-label" htmlFor={`flexCheckDefault_${question.id}`}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled={hasAnswers}
                  onChange={(e) => setRequired(e.target.value)}
                  checked={question.isRequired}
                  id={`flexCheckDefault_${question.id}`}
                />
                {qT('mandatory')}
              </label>
            </div>
          </Col>
          <Col className="col-3">
            <div className="card-actions">
              <button
                className="action-button red-border"
                onClick={onDelete}
              >
                <DeleteIcon />
              </button>
              {optionsAvailable(question.type) && !question.isSaved &&
                <button
                  className="action-button red-border"
                  onClick={onEdit}
                >
                  <EditPencilIcon />
                </button>
              }
              <button
                className="action-button move-up"
                disabled={shouldMoveUpDisabled}
                onClick={() => onMoveUp(question)}
              >
                <UpArrowIcon />
              </button>
              <button
                className="action-button move-down"
                disabled={shouldMoveDownDisabled}
                onClick={() => onMoveDown(question)}
              >
                <DownArrowIcon />
              </button>
            </div>
          </Col>
        </Row>
      </div>

    </div>
  );
}

import React, { PureComponent } from 'react';
import { Button } from 'reactstrap';
import t, { partial } from '../../../util/translation/translation';

const s = partial('shared');
const p = partial('questionaire');
class ItemsArrayField extends PureComponent {
  render() {
    const { onChange, value } = this.props;
    return (
      <div>
        {(value || []).map((url, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`video-${index}`}
            style={{
              display: 'flex',
              alignItems: 'stretch',
              marginBottom: '10px',
            }}
          >
            <input
              type="text"
              value={url}
              onChange={(e) => {
                const newUrls = [...(value || [])];
                newUrls[index] = e.target.value;
                onChange(newUrls);
              }}
              placeholder={t('NewsForm.youtubeVideoID')}
              style={{
                flex: 1,
                marginRight: '10px',
                height: '38px',
              }}
            />
            <Button
              color="danger"
              style={{ height: '38px' }}
              onClick={() => {
                const newUrls = [...(value || [])];
                newUrls.splice(index, 1);
                onChange(newUrls);
              }}
            >
              {s('remove')}
            </Button>
          </div>
        ))}
        <Button
          color="primary"
          onClick={() => {
            onChange([...(value || []), '']);
          }}
        >
          {p('addYoutubeVideo')}
        </Button>
      </div>
    );
  }
}

const renderItemsArrayField = props => (
  <div className="form__form-group-input-wrap">
    <ItemsArrayField
      {...props.input}
    />
    {props.meta.touched && props.meta.error && <span className="form__form-group-error">{props.meta.error}</span>}
  </div>
);



export default renderItemsArrayField;

import React, { useState, useEffect } from 'react';
import { Row, Container, Col, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { Pie, HorizontalBar } from 'react-chartjs-2';

import ExportVariantIcon from 'mdi-react/ExportVariantIcon';
import fileDownload from 'js-file-download';
import Api from '../../../util/api';
import t, { partial } from '../../../util/translation/translation';
import Loading from '../../../shared/components/custom/Loading';
import QuestionType, { QuestionTypeEnum } from '../../events/components/QuestionnaireBuilder/QuestionType';
import TextFieldModal from './TextFieldModal';


const PieChart = ({ item }) => {
    const data = item.answers.map((answer) => answer.count);
    const chartOptions = {
        responsive: true,
        legend: {
            labels: {
                boxWidth: 16,
            },
            position: 'right',
        },
        maintainAspectRatio: false,
    };

    const labels = item.answers.map(
        (answer) => `${answer.label}`,
    );
    return (
      <div className="chart-container">
        <div className="chart-style">
          <Pie
            data={{
                        datasets: [
                            {
                                data,
                                backgroundColor: [
                                    '#EF6C6C',
                                    '#8D66F5',
                                    '#2388FB',
                                    '#47D78A',
                                    '#F0B26A',
                                ],
                                borderWidth: 0,
                            },
                        ],
                        labels,
                    }}
            options={chartOptions}
          />
        </div>
      </div>
    );
};
const BarChart = ({ item }) => {
    const data = item.answers.map((answer) => answer.count);
    const chartOptions = {
        responsive: true,
        legend: false,
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
              ticks: {
                beginAtZero: true,
                stepSize: 1,
                precision: 0,
              },
            }],
          },
      };
    const labels = item.answers.map(
        (answer) => `${answer.label}`,
    );
    return (
      <div className="chart-container">
        <div className="chart-style">
          <HorizontalBar
            options={chartOptions}
            data={{
              labels,
              datasets: [
                {
                  data,
                  maxBarThickness: 16,
                  backgroundColor: [
                    '#EF6C6C',
                    '#8D66F5',
                    '#2388FB',
                    '#47D78A',
                    '#F0B26A',
                ],
                },
              ],
            }}
          />
        </div>
      </div>
    );
};
const TextChart = ({ item, itemPress }) => {
    const qS = partial('question');
    const textFieldQuestionLimit = 5;
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ width: 600 }}>
          <Row>
            <div className="recent-answers">
              <p>{qS('recentAnswers')}</p>
            </div>
          </Row>
          {item.answers.map(
          (answer, index) =>
            answer.text !== '' &&
            index < textFieldQuestionLimit && (
              <Row key={answer.text}>
                <div style={{
                width: '100%',
                display: 'flex',
                padding: '16px',
                borderRadius: '4px',
                border: '1px solid #e8eaed',
                backgroundColor: '#fff',
                minHeight: '48px',
              }}
                >
                  <p style={{
                  color: '#121b2b',
                  width: '100%',
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word',
                  margin: 0,
                }}
                  >
                    {answer.text}
                  </p>
                </div>
              </Row>
            ),
        )}
          {item.answers.length > textFieldQuestionLimit && (
            <Row className="justify-content-center">
              <div style={{ width: 200, marginTop: 8 }}>
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => itemPress(item)}
                >
                  {qS('viewAllResponses')}
                </Button>
              </div>
            </Row>
        )}
        </div>
      </div>

    );
  };


const AnswerItem = ({ item, itemPress }) => {
    switch (item.type) {
        case QuestionTypeEnum.DROPDOWN: return <PieChart item={item} />;
        case QuestionTypeEnum.RADIO_GROUP: return <PieChart item={item} />;
        case QuestionTypeEnum.SCORE_PREDICTOR: return <PieChart item={item} />;
        case QuestionTypeEnum.CHECKBOX: return <BarChart item={item} />;
        case QuestionTypeEnum.PLAYER_PICKER: return <BarChart item={item} />;
        case QuestionTypeEnum.TEXT_FIELD: return <TextChart item={item} itemPress={itemPress} />;
        default: return null;
    }
};

const mapQuestionType = (type) => {
    switch (type) {
        case 'TEXT_INPUT': return QuestionTypeEnum.TEXT_FIELD;
        case 'CHECKBOX': return QuestionTypeEnum.CHECKBOX;
        case 'DROPDOWN': return QuestionTypeEnum.DROPDOWN;
        case 'RADIO_BUTTONS': return QuestionTypeEnum.RADIO_GROUP;
        case 'SCORE_PREDICTOR': return QuestionTypeEnum.SCORE_PREDICTOR;
        case 'PLAYER_PICKER': return QuestionTypeEnum.PLAYER_PICKER;
        default: return null;
    }
};
const QuestionaireStatsPage = ({ questionaireId, title }) => {
    const [loading, setLoading] = useState(false);
    const [questionNumber, setQuestionNumber] = useState(null);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [stats, setStats] = useState([]);
    const s = partial('shared');
    const qS = partial('question');

    const getQuestionaireStats = async () => {
        setLoading(true);
        try {
            const [statsData, allQuestions] = await Promise.all([
              Api.questionaire.getStatsForQuestionaire(questionaireId),
              Api.questionaire.getQuestionsForQuestionaire(questionaireId),
            ]);
            const mappedData = statsData.map((item) => {
              if (item.type === 'SCORE_PREDICTOR') {
                const foundQuestion = allQuestions.find((question) => question.id === item.questionId);
                const currentQuestion = foundQuestion?.value?.availableOptions.sort((a, b) => a.id - b.id).map((option) => option.title);
                const constructedPrefix = currentQuestion.join(' - ');
                const newAnswers = [];
                for (let i = 0; i < item.answers.length; i++) {
                  newAnswers.push({
                    ...item.answers[i],
                    label: `${constructedPrefix} ${item.answers[i].label}`,
                  });
                }
                return ({ ...item, type: mapQuestionType(item.type), answers: newAnswers });
              }
              if (item.type === 'PLAYER_PICKER') {
                const newAnswers = [];
                for (let i = 0; i < item.answers.length; i++) {
                  newAnswers.push({
                    ...item.answers[i],
                    label: item.answers[i].label,
                  });
                }
                return ({ ...item, type: mapQuestionType(item.type), answers: newAnswers });
              }
              return ({ ...item, type: mapQuestionType(item.type) });
            });
            setStats(mappedData);
        } catch (error) {
            toast.error(s('somethingWentWrong'));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getQuestionaireStats();
    }, [questionaireId]);

    const handleQuestionPress = (_number) => (_answer) => {
        setQuestionNumber(_number);
        setSelectedAnswer(_answer);
    };
    const handleCSVExport = async () => {
      try {
        const { rows } = await Api.questionaire.getAnswersForQuestionaire(questionaireId);
        const [firstRow, ...restRows] = rows;
        const [_first, _last, _phone, _email, _answeredAt, ...questionTitles] = firstRow;
        let csv = `${s(_first)};${s(_last)};${s(_phone.toLowerCase())};${s(_email)};${t(`questionaire.${_answeredAt}`)};${questionTitles.join(';')}\n`;
        restRows.forEach((row) => {
          csv += `${row.join(';')}\n`;
        });
        fileDownload(`\uFEFF${csv}`, `${title}.csv`);
      } catch (e) {
        console.log(e);
        toast.error(s('somethingWentWrong'));
      }
    };


    if (loading) return <Loading loading={loading} />;
    return (
      <>
        <TextFieldModal selectedAnswer={selectedAnswer} isVisible={!!selectedAnswer} questionNumber={questionNumber} close={() => { setSelectedAnswer(null); setQuestionNumber(null); }}/>

        <Container className="margin-10">
          <Col>
            <Row>
              <Button
                onClick={handleCSVExport}
                outline
                style={{ color: '#0469DC', fontWeight: 600 }}
                className="flex align-center margin-0"
              >
                <ExportVariantIcon
                  style={{
                height: '20px',
                width: 'auto',
                fill: '#0469DC',
                position: 'relative',
                top: '-2px',
              }}
                />
                {qS('exportCSV')}
              </Button>
            </Row>
            {stats.map((item, idx) =>
              <Row key={`question-${item.title}`}>
                <div
                  className="summary-question-card card-margin"
                >
                  <div className="content-area">
                    <Row>
                      <Col className="question-header">
                        <div className="question-id">
                          <p>{String((idx + 1)).padStart(2, '0')}</p>
                        </div>
                        <div className="question-description">
                          <h5>{item.title}</h5>
                          <p>
                            {`${qS('answers')} ${item.totalAnswers} ${qS('users')} ${item.totalUsers}`}
                          </p>
                        </div>
                      </Col>
                      <Col className="col-3 d-flex justify-content-end">
                        <QuestionType type={item.type} />
                      </Col>
                    </Row>
                  </div>
                  <hr />
                  <div className="content-area">
                    <div className="question-content">
                      <AnswerItem item={item} itemPress={handleQuestionPress(String((idx + 1)).padStart(2, '0'))}/>
                    </div>
                  </div>
                </div>
              </Row>,
                )}
          </Col>

        </Container>
      </>

    );
};

export default QuestionaireStatsPage;

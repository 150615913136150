/* eslint-disable no-unreachable */
import React, { useState, useEffect } from 'react';
import { Container, Row, Card, CardBody, TabPane, TabContent, Col } from 'reactstrap';
import { useParams, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import NavTab from '../../shared/components/custom/NavTab';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import { partial } from '../../util/translation/translation';
import QuestionForm from './components/QuestionForm';
import { questionValueToPayload } from './components/utils';

const QuestionFormPage = () => {
  const { questionID } = useParams();
  const history = useHistory();
  const [tab, setTab] = useState('0');
  const [question, setQuestion] = useState(null);
  const [loading, setLoading] = useState(false);
  const p = partial('questionaire');
  const s = partial('shared');

  const getQuestion = async () => {
    setLoading(true);
    try {
      const resQuestion = await Api.questionaire.getQuestion(questionID);
      setQuestion(resQuestion);
      setLoading(false);
    } catch (err) {
      toast.error(JSON.stringify(err));
      setLoading(false);
    }
  };


  useEffect(() => {
    if (questionID) {
      getQuestion();
    }
  }, [questionID]);


  const onQuestionSubmit = async (values, questionValue) => {
    const payload = {
      type: values.type.value,
      description: values.description,
      image: values?.headingImage?.croppedBase64 || values?.headingImage?.preview,
      title: values.title,
      value: questionValueToPayload(questionValue),
      imageHeaderType: values?.imageHeaderType?.value,
      headingVideos: values?.headingVideos,
    };

    if (values.headingImage?.croppedBase64) {
      const sizeInBytes = 4 * Math.ceil((values.headingImage.croppedBase64.length / 3)) * 0.5624896334383812;
      const sizeInKb = sizeInBytes / 1000;
      const convKbToKB = sizeInKb * 0.125;

      if (convKbToKB > 10000) {
        toast.error(s('imageTooLarge'));
        return;
      }
    }

    setLoading(true);
    try {
      if (questionID) {
        await Api.questionaire.updateQuestion(questionID, payload);
      } else {
        await Api.questionaire.createQuestion(payload);
      }
      history.push('/questionnaires?tab=2');
      toast.success(p('questionUpdated'));
    } catch (err) {
      toast.error(err || s('somethingWentWrong'));
    } finally {
        setLoading(false);
    }
  };


  return (
    <Container>
      <Loading loading={loading} />
      <Row>
        <Col>
          <Card>
            <CardBody className="p-0">
              <NavTab
                tabs={[
                  { tab: `${s('details')}` },
                ]}
                setTab={setTab}
                activeTab={tab}
                size="sm"
              />
              <TabContent activeTab={tab}>
                <TabPane tabId="1" className="m-3">
                  <QuestionForm type={questionID ? 'edit' : 'create'} question={question} onSubmit={onQuestionSubmit}/>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default QuestionFormPage;

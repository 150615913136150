import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Table, UncontrolledDropdown } from 'reactstrap';
import ArrowUpIcon from 'mdi-react/ArrowUpIcon';
import ArrowDownIcon from 'mdi-react/ArrowDownIcon';
import useModal from '../../shared/hooks/useModal';
import Api from '../../util/api';
import DeleteModal from '../../shared/components/custom/DeleteModal';
import Pagination from '../../shared/components/pagination/Pagination';
import { partial } from '../../util/translation/translation';
import { formatDate } from '../../util/date-and-time';

const QuestionListPage = ({ setLoading }) => {
  const [questions, setQuestions] = useState([]);
  const [handleModal, visible, deleteID] = useModal();
  const [pagination, setPagination] = useState({
    total: 0,
    currentPage: 1,
    perPage: 10,
  });
  const [order, setOrder] = useState({
    orderBy: 'createdAt',
    desc: true,
  });
  const p = partial('questionaire');
  const s = partial('shared');

  const fetchQuestions = async (currentPage = 1, pageSize = 10, orderObject = {
    orderBy: 'createdAt',
    desc: true,
  }) => {
    setLoading(true);
    try {
      const reqQuestions = await Api.questionaire.getQuestions({ page: currentPage, take: pageSize }, { orderBy: orderObject.orderBy, direction: orderObject.desc ? 'DESC' : 'ASC' });
      setQuestions(reqQuestions.data);
      setPagination(old => ({ ...old, total: reqQuestions.count }));
      setLoading(false);
    } catch (err) {
      toast.error(p('failedToFetchQuestionaires'));
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchQuestions();
  }, []);
  const deleteQuestion = async () => {
    setLoading(true);
    try {
      await Api.questionaire.deleteQuestion(deleteID);
      toast.success(p('questionDeleted'));
    } catch (err) {
      toast.error(p('questionDeleteError'));
    } finally {
      handleModal();
      fetchQuestions(pagination.currentPage, pagination.perPage, order);
    }
  };

  const onChangePage = (pager) => {
    if (pager.pageSize !== pagination.perPage || pager.currentPage !== pagination.currentPage) {
      setPagination(old => ({
        ...old,
        currentPage: pager.currentPage,
        perPage: pager.pageSize,
      }));
      fetchQuestions(pager.currentPage, pager.pageSize, order);
    }
  };

  const renderSort = (cellOrder) => {
    if (order.orderBy !== cellOrder) {
      return undefined;
    }
    return order.desc ?
      <ArrowDownIcon size={16} />
      :
      <ArrowUpIcon size={16} />;
  };
  const handleSort = (incommingOrder) => {
    const newOrder = {
      orderBy: incommingOrder,
      desc: incommingOrder === order.orderBy ? !order.desc : true,
    };
    setOrder(newOrder);
    setPagination({
      total: 0,
      currentPage: 1,
      perPage: 10,
    });
    fetchQuestions(1, 10, newOrder);
  };


  const renderQuestions = () => {
    return questions.map((question, i) => {
      return (
        <tr style={{ background: (i) % 2 ? '#fff' : '#F9FAFB' }} key={`question-${question.id}`}>
          <td>
            <div className="flex-Vcenter">
              <Link to={`/questionnaires/questions/edit/${question.id}`}>{question.title}</Link>
            </div>
          </td>
          <td>{formatDate(question.createdAt)}</td>
          <td>{question.type}</td>
          <td>
            <UncontrolledDropdown className="dashboard__table-more">
              <DropdownToggle>
                <DotsHorizontalIcon />
              </DropdownToggle>
              <DropdownMenu className="dropdown__menu">
                <Link to={`/questionnaires/questions/edit/${question.id}`}>
                  <DropdownItem>{s('edit')}</DropdownItem>
                </Link>
                <DropdownItem onClick={() => handleModal(question.id)} className="danger">{s('delete')}</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <DeleteModal
        visible={visible}
        type={p('theQuestion')}
        handleModal={handleModal}
        modalAction={deleteQuestion}
      />
      <Row>
        <Col>
          <div className="float-right marginTop-0">
            <Link to="/questionnaires/questions/create"><Button size="sm" color="primary">{p('createQuestion')}</Button></Link>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div style={{ display: 'flex', paddingBottom: 5, background: '#fff', borderRadius: 6, boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)' }}>
            <Table responsive striped >
              <thead>
                <tr>
                  <th style={{ color: '#4D576A' }} className="sortable" onClick={() => handleSort('title')}>
                    <div className="flex alignItems-center">{s('title')} {renderSort('title')}</div>
                  </th>
                  <th style={{ color: '#4D576A' }} className="sortable" onClick={() => handleSort('createdAt')}>
                    <div className="flex alignItems-center">{s('created')} {renderSort('createdAt')}</div>
                  </th>
                  <th style={{ color: '#4D576A' }} onClick={() => handleSort('type')}>
                    <div className="flex alignItems-center">{s('type')} {renderSort('type')}</div>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {renderQuestions()}
              </tbody>
            </Table>
          </div>
          <Pagination
            items={pagination.total}
            onChangePage={onChangePage}
            initialPage={pagination.currentPage}
          />
        </Col>
      </Row>
    </>
  );
};

export default QuestionListPage;

import React, { useEffect, useState } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { Row, Col, Container, Button } from 'reactstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';
import renderField from '../../../shared/components/custom/Field';
import renderDropZoneField from '../../../shared/components/form/DropZoneMS';
import validateQuestionForm from './validateQuestionForm';
import { partial } from '../../../util/translation/translation';
import { getImageHash } from '../../../util/functions';
import renderSelectField from '../../../shared/components/form/Select';
import QuestionBuilder from './QuestionBuilder';
import { payloadToQuestionValue } from './utils';
import renderItemsArrayField from '../../../shared/components/form/ItemsArray';

const QuestionForm = ({
  question, handleSubmit, initialize, formValues, onSubmit, onClose,
}) => {
  const s = partial('shared');
  const p = partial('questionaire');

  const questionTypes = ['TEXT_INPUT', 'PLAYER_PICKER', 'RADIO_BUTTONS', 'CHECKBOX', 'SCORE_PREDICTOR', 'DROPDOWN'].map((item) => ({
    label: p(`questionType-${item}`),
    value: item,
  }));
  const imageHeaderTypes = ['IMAGE', 'VIDEO'].map((item) => ({
    label: p(`imageHeaderType-${item}`),
    value: item,
  }));
  const [questionValue, setQuestionValue] = useState([]);

  useEffect(() => {
    if (question) {
      initialize({
        headingImage: question.imageUrl ? {
          name: 'question image',
          preview: question.imageUrl,
          croppedImage: question.imageUrl,
          hash: getImageHash(question.imageUrl),
        } : null,
        headingVideos: question.headingVideos || [],
        title: question.title,
        description: question.description,
        type: { label: p(`questionType-${question.type}`), value: question.type },
        imageHeaderType: { label: p(`imageHeaderType-${question.imageHeaderType ?? 'IMAGE'}`), value: question.imageHeaderType ?? 'IMAGE' },
      });

      setQuestionValue(payloadToQuestionValue(question.value, question.type));
    } else {
      initialize({
        type: { label: p('questionType-TEXT_INPUT'), value: 'TEXT_INPUT' },
        imageHeaderType: { label: p('imageHeaderType-IMAGE'), value: 'IMAGE' },
      });
      setQuestionValue([
        {
          id: 0,
          type: 'TEXT_INPUT',
          value: '',
        },
      ]);
    }
  }, [question]);

  const onTypeChange = (_type) => {
    if (_type.value === 'SCORE_PREDICTOR') {
      setQuestionValue([
        {
          id: 0,
          type: _type.value,
          value: '',
        },
        {
          id: 1,
          type: _type.value,
          value: '',
        },
      ]);
    } else {
      setQuestionValue([
        {
          id: 0,
          type: _type.value,
          value: '',
        },
      ]);
    }
  };
  return (
    <form className="form" onSubmit={handleSubmit(data => onSubmit(data, questionValue))}>
      <Container>
        <Row>
          <Col>
            <div style={{ backgroundColor: '#F4F4F6', borderRadius: 4, borderColor: '#D7DAE0', borderStyle: 'solid', borderWidth: 1, marginBottom: 10, width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderBottomWidth: 1, borderBottomStyle: 'dashed', borderColor: '#D7DAE0', padding: '10px 10px 10px 10px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ fontSize: 16, lineHeight: '24px', color: 'black', fontWeight: 'bold' }}>{formValues?.imageHeaderType?.value === 'IMAGE' ? s('image') : s('video')}</div>
                </div>
              </div>
              <div style={{ padding: '10px' }}>
                <Container>
                  <Row>
                    <Col md={12}>
                      {formValues?.imageHeaderType?.value === 'IMAGE' ?
                        <div className="form__form-group">
                          <Field
                            saveBase64Cropped
                            name="headingImage"
                            component={renderDropZoneField}
                            displayCrop
                            crop="BANNER_CROP"
                          />
                          <span className="form-header"><strong>{s('note')}:</strong> {s('recommendedImageSize')} 16:9 (1035 x 570 px)</span>
                        </div> :
                        <div className="form__form-group">
                          <Field
                            name="headingVideos"
                            component={renderItemsArrayField}
                          />
                        </div>}
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
            <div style={{ backgroundColor: '#F4F4F6', borderRadius: 4, borderColor: '#D7DAE0', borderStyle: 'solid', borderWidth: 1, marginTop: 10, marginBottom: 10, width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderBottomWidth: 1, borderBottomStyle: 'dashed', borderColor: '#D7DAE0', padding: '10px 10px 10px 10px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ fontSize: 16, lineHeight: '24px', color: 'black', fontWeight: 'bold' }}>{s('details')}</div>
                </div>
              </div>
              <div style={{ padding: '10px' }}>
                <Col>
                  <div className="form__form-group">
                    <span className="form-header">{s('question')}*</span>
                    <Field
                      name="title"
                      component={renderField}
                      placeholder={s('question')}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="form__form-group">
                    <span className="form-header">{s('subtitle')}</span>
                    <Field
                      name="description"
                      component={renderField}
                      placeholder={s('subtitle')}
                    />
                  </div>
                </Col>
                <Row>
                  <Col>
                    <div className="form__form-group">
                      <span className="form-header">{p('selectType')}*</span>
                      <div className="form__form-group-field">
                        <Field
                          name="type"
                          placeholder={p('selectType')}
                          options={questionTypes}
                          component={renderSelectField}
                          onChange={onTypeChange}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="form__form-group">
                      <span className="form-header">{p('imageHeaderType')}*</span>
                      <div className="form__form-group-field">
                        <Field
                          name="imageHeaderType"
                          placeholder={p('selectType')}
                          options={imageHeaderTypes}
                          component={renderSelectField}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

              </div>
            </div>
            {questionValue[0]?.type !== 'TEXT_INPUT' &&
              <div style={{ width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ fontSize: 16, lineHeight: '24px', color: 'black', fontWeight: 'bold' }}>{p('answeringOptions')}</div>
                </div>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <Row>
                    <QuestionBuilder pickedOptions={questionValue} setPickedOptions={setQuestionValue} type={formValues?.type} />
                  </Row>
                </div>
              </div>
            }
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Button type="submit" color="primary">{question ? s('update') : s('create')}</Button>
            {onClose ?
              <Button type="button" color="secondary" onClick={onClose}>{s('close')}</Button> :
              <Link to="/questionnaires?tab=2"> <Button type="submit" color="secondary">{s('close')}</Button></Link>
            }
          </Col>
        </Row>
      </Container>
    </form>
  );
};



const reduxF = reduxForm({
  form: 'question_form',
  asyncValidate: validateQuestionForm,
});
const mapStateToProps = (state) => ({
  formValues: getFormValues('question_form')(state),
});
export default connect(mapStateToProps)(reduxF(QuestionForm));




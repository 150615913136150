import React, { useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import countries from 'i18n-iso-countries';
import { Button, Container, Row, FormGroup, FormText, Label, Col } from 'reactstrap';
import { useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import renderField from '../../../shared/components/custom/Field';
import renderSelectField from '../../../shared/components/form/Select';
import userLanguageState from '../../../recoil/userLanguage/selector';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import Api from '../../../util/api';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import renderDropZoneField from '../../../shared/components/form/DropZone';
import renderDropZoneFieldNew from '../../../shared/components/form/DropZoneNew';
import { partial } from '../../../util/translation/translation';

countries.registerLocale(require('i18n-iso-countries/langs/nb.json'));
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/sv.json'));

const ClubForm = ({ handleSubmit, club, submitter, initialize, season, ntbStages }) => {
  const p = partial('seasonObjectsShared');

  const [teams, setTeams] = useState([]);
  const [showTeamForm, setShowTeamForm] = useState(false);
  const [pickedTeams, setPickedTeams] = useState(club?.administrates.map(team => ({ label: team.name, value: team })) || []);
  const userLanguage = useRecoilValue(userLanguageState);
  const countryOptions = Object.entries(countries.getNames(userLanguage)).map(([iso, country]) => ({
    name: country,
    iso,
    value: iso,
    label: country,
  })).sort((a) => (a.value === 'NO' || a.value === 'SE' ? -1 : 1));
  const createTeam = async (values) => {
    const { team } = values;
    try {
      const { data } = await Api.clubadmin.createTeam(team);
      await Api.clubadmin.teams.update(data.id, {
        ...data,
        externalProviderId: `club:${club.id}:${data.shortName}`,
        canCreatePlayers: true,
      });
      const _team = { label: data.name, value: data };
      setPickedTeams([...pickedTeams, _team]);
      setTeams([...teams, _team]);
    } catch (e) {
      toast.error(e);
    }
  };
  useEffect(() => {
    if (club) {
      initialize({
        ...club,
        config: {
          ...club.config,
          featureFlags: club.config.featureFlags.map(ff => ({ label: ff, value: ff })),
          club_logo: club.config.club_logo ? [{ src: club.config.club_logo, value: club.config.club_logo }] : undefined,
          seasonId: ntbStages.find(stage => stage.value === `${season?.value}`),
        },
        teams: club.administrates.map((team) => ({ label: team.name, value: team })),
      });
    }
  }, [club, season, ntbStages]);
  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const { data } = await Api.clubadmin.teams.fetchTeams();
        setTeams(data.map(team => ({ label: team.name, value: team })));
      } catch (e) {
        toast.error(e);
      }
    };
    fetchTeams();
  }, []);
  return (
    <>
      <form onSubmit={handleSubmit(submitter)} className="form">
        <Container>
          <FormGroup row>
            <Col sm={2}>
              <Label htmlFor="config.club_name">Name</Label>
            </Col>
            <Col sm={2}>
              <Field
                name="config.club_name"
                component={renderField}
                type="text"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={2}>
              <Label htmlFor="config.club_logo">Logo</Label>
            </Col>
            <Col sm={2}>
              <Field
                name="config.club_logo"
                component={renderDropZoneFieldNew}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={2}>
              <Label htmlFor="config.club_name_short">Club shortname</Label>
            </Col>
            <Col sm={2}>
              <Field
                name="config.club_name_short"
                component={renderField}
                type="text"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={2}>
              <Label htmlFor="config.app_name">App short name</Label>
            </Col>
            <Col sm={2}>
              <Field
                name="config.app_name"
                component={renderField}
                type="text"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={2}>
              <Label htmlFor="config.cloudIdName">Cloud Id name</Label>
            </Col>
            <Col sm={2}>
              <Field
                name="config.cloudIdName"
                component={renderField}
                type="text"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={2}>
              <Label htmlFor="id">Set Id</Label>
            </Col>
            <Col sm={2}>
              <Field
                name="id"
                component={renderField}
                type="number"
              />
              <FormText>Set ntb_id for old clubs, not needed for new clubs</FormText>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={2}>
              <Label for="config.isoCountry">ISO country code</Label>
            </Col>
            <Col sm={2}>
              <Field
                name="config.isoCountry"
                component={renderSelectField}
                options={countryOptions}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={2}>
              <Label htmlFor="config.sport">Sport</Label>
            </Col>
            <Col sm={2}>
              <Field
                name="config.sport"
                component={renderField}
                type="text"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={2}>
              <Label htmlFor="config.featureFlags">Feature flags</Label>
            </Col>
            <Col sm={2}>
              <Field
                name="config.featureFlags"
                component={renderSelectField}
                options={[
                  {
                    label: 'Lottery',
                    value: 'lottery',
                  },
                  {
                    label: 'Tickets',
                    value: 'tickets',
                  },
                  {
                    label: 'Kiosk',
                    value: 'cnc',
                  },
                  {
                    label: 'Auction',
                    value: 'auction',
                  },
                  {
                    label: 'External store',
                    value: 'externalStore',
                  },
                  {
                    label: 'External tickets',
                    value: 'externalTickets',
                  },
                  {
                    label: 'Deals',
                    value: 'deals',
                  },
                  {
                    label: 'Youtube feed',
                    value: 'youTubeFeed',
                  },
                ]}
                multiple
                type="option"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={2}>
              <Label htmlFor="config.firebaseProject">Firebase project name</Label>
            </Col>
            <Col sm={2}>
              <Field
                name="config.firebaseProject"
                component={renderField}
                type="text"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={2}>
              <Label htmlFor="config.clubSportalityAvailable">Club sportality</Label>
            </Col>
            <Col sm={2}>
              <Field
                name="config.clubSportalityAvailable"
                component={renderCheckBoxField}
                type="checkbox"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={2}>
              <Label htmlFor="config.leagueSportalityAvailable">League sportality</Label>
            </Col>
            <Col sm={2}>
              <Field
                name="config.leagueSportalityAvailable"
                component={renderCheckBoxField}
                type="checkbox"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={2}>
              <Label htmlFor="config.currency">Currency</Label>
            </Col>
            <Col sm={2}>
              <Field
                name="config.currency"
                component={renderField}
                type="text"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={2}>
              <Label htmlFor="teams">Team administration</Label>
            </Col>
            <Col sm={3}>
              <span>
                <Field
                  name="teams"
                  component={renderSelectField}
                  options={teams}
                  type="options"
                  multiple
                  value={pickedTeams}
                />
                <Button size="sm" color="success" onClick={() => setShowTeamForm(true)}>Create new team</Button>
              </span>
            </Col>
          </FormGroup>
          {showTeamForm && (
            <>
              <FormGroup row>
                <Col sm={2}>
                  <Label htmlFor="team.name">Team Name</Label>
                </Col>
                <Col sm={2}>
                  <Field
                    name="team.name"
                    component={renderField}
                    type="text"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={2}>
                  <Label htmlFor="team.shortName">Team Shortname</Label>
                </Col>
                <Col sm={2}>
                  <Field
                    name="team.shortName"
                    component={renderField}
                    type="text"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={2}>
                  <Label htmlFor="team.image">Team logo</Label>
                </Col>
                <Col sm={2}>
                  <Field
                    name="team.image"
                    component={renderDropZoneField}
                    type="image"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={2}>
                  <Label htmlFor="team.gender">Gender</Label>
                </Col>
                <Col sm={2}>
                  <Field
                    name="team.gender"
                    component={renderRadioButtonField}
                    radioValue="male"
                    label="male"
                    defaultChecked
                  />
                  <Field
                    name="team.gender"
                    component={renderRadioButtonField}
                    radioValue="female"
                    label="female"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={2}>
                  <Label htmlFor="team.ageLeague">Age league</Label>
                </Col>
                <Col sm={2}>
                  <Field
                    name="team.ageLeague"
                    component={renderField}
                    type="text"
                  />
                  <FormText>Senior, U18 ...</FormText>
                </Col>
              </FormGroup>
              <Button color="info" onClick={handleSubmit(createTeam)}>Create Team</Button>
            </>
          )}
          <FormGroup row>

            <Col sm={2}>
              <Label htmlFor="config.seasonId">{p('seasonNtbId')}</Label>
            </Col>
            <Col sm={2}>
              <Field
                name="config.seasonId"
                component={renderSelectField}
                options={ntbStages}
              />
            </Col>
          </FormGroup>
          <Row>
            <Button color="primary" type="submit">{club ? 'Edit' : 'Create'}</Button>
            <Link to="/clubadmin"><Button color="secondary">Close</Button></Link>
          </Row>
        </Container>
      </form>
    </>
  );
};
const form = reduxForm({
  form: 'ClubForm',
});
export default form(ClubForm);
